import React from 'react';
import { graphql } from 'gatsby';
import { ContactQuery } from '@types';
import { PageContact } from '@src/ui';
import { usePageList } from '@src/hooks/page-list';
import { adaptImage } from '@src/adapters/image';

interface Props {
  data: ContactQuery;
}

const Page: React.FC<Props> = ({ data }) => {
  const pages = usePageList();
  const currentPageData = pages.find(p => p.id === 'contact');
  const contactImage = adaptImage(data.contactImage);
  return (
    <PageContact image={currentPageData?.image} contactImage={contactImage} />
  );
};

export const query = graphql`
  query Contact {
    contactImage: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 240, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Page;
